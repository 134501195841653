.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-bottom: 100px;
  font-size: 24px;
}

input:focus {
  outline: 2px solid #1185a2;     /* #ff0000! yey */
}

textarea:focus {
  outline: 2px solid #1185a2;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* OUR BLUE -  #1185a2 */

.input-input {
  font-size: 20px;
  padding: 5px;
  border: solid 2px #1185a2;
  border-radius: 6px;
  width: 220px;
}

.final-text-input {
  font-size: 26px;
  width: 480px;
  border-radius: 8px;
  padding: 12px;
}

.fast-onboard-container {
  margin-top: 10px;
  align-self: center;
  flex-direction: column;
  display: flex;
  width: 500px;
  margin-left: 5px;
  margin-right: 5px;
}

.fast-onboard-button {
  cursor: pointer;
  padding: 10px;
  border: solid 3px #5070ed;
  border-radius: 8px;
}

.tab-container {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  border-bottom: solid 4px #1185a2;
  padding-left: 40px;
  width: 590px;
}

.tab-active {
  border-top: solid 4px #1185a2;
  border-left: solid 4px #1185a2;
  border-right: solid 4px #1185a2;
  background-color: #1185a2;
  color: #ffffff;
}

.tab-inactive {
  border-top: solid 4px #1185a2;
  border-left: solid 4px #1185a2;
  border-right: solid 4px #1185a2;
  color: #1185a2;
}

.tab-container-tab {
  display: flex;
  border-radius: 12px 12px 0px 0px;
  padding: 12px;
  margin-left: 20px;
}

.get-yours {
  color: #1185a2;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
}

.copy-icon {
  margin-left: 8px;
  width: 18px;
  display: flex;
  margin-top: -6px;
  margin-right: 6px;
}

.your-liaison-details-label {
  margin-bottom: 10px;
  width: 380px;
  text-align: end;
  padding-bottom: 4px;
  font-weight: bold;
  border-bottom: 2px solid #cccccc;
}

.your-liaison-details {
  display: flex;
  flex-direction: column;
  align-self: end;
  align-items: end;
  align-content: end;
  position: absolute;
  top: 18px;
  right: 22px;
}

.input-label {
  background-color: #ffffff;
  margin-left: 15px;
  font-size: 18px;
  color: #404040;
  align-items: left;
  z-index: 999999;
  position: relative;
}

.input-wrapper {
  display: flex;
  width: 220px;
  margin-top: -6px;
  flex-direction: column;
}

.base-button {
  border: solid 3px #1185a2;
  font-weight: bold;
  border-radius: 12px;
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  padding: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #444444;
}

.qr-button {
  align-self: end;
  margin-top: 12px;
  width: 108px;
}

.logout {
  position: absolute;
  top: 44px;
  left: 110px;
  display: flex;
  font-size: 20px;
  text-decoration: underline;
  padding-bottom: 10px;
  color: #1185a2;
  cursor: pointer;
}

.stats {
  /* border: solid 1px #cccccc; */
  margin-bottom: 48px;
  padding: 4px;
}

.stats-row {
  display: flex;
  flex-direction: row;
  padding: 4px;
}

.stats-label {
  display: flex;
  padding: 5px;
  width: 230px;
}

.stats-value {
  display: flex;
  padding: 5px;
  width: 300px;
  color: #1185a2;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.login-input {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.qrmodal {
  z-index: 99999999;
  position: fixed;
  top: 60px;
  margin-left: 50px;
  margin-right: 50px;
  border: solid 2px #202020;
  display: flex;
  left: 46%;
  width: 440px;
  transform: translate(-50%, 0)
}

.loggedin-box {
  position: fixed;
  top: 0px;
  padding-top: 14px;
  /* right: 40px; */
  width: 95%;
  display: flex;
  height: '80px';
  background-color: #ffffff;
}

.login-box {
  border: solid 1px #505050;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  width: 450px;
  align-items: center;
  align-self: center;
  padding: 20px;
}

.signin-button {
  margin-top: 22px;
}

.login-right-side {
  width: 400px;
  flex-direction: column;
  display: flex;
  flex: 1;
}

.logo {
  margin-top: 180px;
  align-self: center;
  width: 500px;
  /* margin-bottom: -170px; */
}

.logo-key {
  align-self: center;
  width: 240px;
  margin-top: 28px;
  margin-bottom: 40px;
}

.question-head {
  display: flex;
  flex-direction: row;
}

.question {
  flex-direction: column;
  display: flex;
  width: 720px;
}

.response {
  padding: 12px;
  margin-bottom: 20px;
  align-self: end;
  display: flex;
  flex-direction: row;
}

.submit-match-button {
  margin-left: 50px;
  margin-right: 50px;
}

.progress-indicator {
  padding: 4px;
  width: 200px;
  height: 18px;
  border: solid 1px #cccccc;
  border-radius: 6px;
}

.question-response {
  width: 500px;
  padding: 5px;
  border-radius: 6px;
  color: #1185a2;
  font-size: 24px;
}

.bot-question {
  width: 500px;
  display: flex;
  padding-bottom: 24px;
  font-weight: bold;
  font-size: 28px;
}

.bot-response-button {
  width: 120px;
  margin-top: 30px;
}

.bot-question-container {
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
}

.bot-question-response {
  display: flex;
}

.question-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  margin-top: -50px;
}

.main-content {
  font-size: 30px;
  padding-bottom: 20px;
  width: 730px;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.hitme-button {
  margin-top: 10px;
  margin-left: 8px;
  margin-right: 8px;
  border: solid 4px #666666;
  margin-top: 10px;
  margin-left: 8px;
}

.xbutton {
  cursor: pointer;
}

.help-content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 730px;
  padding: 10px;
  font-weight: bold;
  font-size: 28px;
  padding-bottom: 28px;
}

.question-input {
  width: 680px;
  height: 140px;
  display: flex;
  border-radius: 12px;
  margin-left: 6px;
  margin-bottom: 8px;
  padding: 6px;
  font-size: 28px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
